import React from 'react'
import { Col, config, Row, Visible } from 'react-awesome-styled-grid'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'

import BlockItem from '~/components/BlockItem'
import FeedItem from '~/components/FeedItem'
import HorizontalAd from './ads/HorizontalAd'
import VerticalAd from './ads/VerticalAd'

/**
 * ResponsivePostGrid
 */

const ResponsivePostGrid = ({ className, data, title, contained, feed, showCategory }) => {
  return (
    <Row justify={{ md: 'center' }}>
      <GridColumn xs={12} xl={9} contained={contained}>
        {title && <h2>{title}</h2>}
        <StyledRow>
          {data.map(list =>
            list.map(item => {
              if (feed) {
                return (
                  <Col key={item.id}>
                    <FeedItem showCategory={showCategory} key={item.id} item={item} />
                  </Col>
                )
              }

              return (
                <Col key={item.id} xs={12} sm={12} md={6} lg={4}>
                  <BlockItem item={item} showCategory={showCategory} />
                </Col>
              )
            })
          )}
        </StyledRow>
      </GridColumn>
      <Visible xs={false} sm={false} md={false} lg={false} xl>
        <AdColumn xl={3} align="flex-end">
          <StyledAd title={title} className={className} name={`list-${title}-column`} />
        </AdColumn>
      </Visible>
      <Visible xs={false} sm={false} md={false} lg xl={false}>
        <HorizontalAd name={`list-${title}-horizontal`} />
      </Visible>
    </Row>
  )
}

/**
 * PropTypes
 */

ResponsivePostGrid.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  title: PropTypes.string,
  contained: PropTypes.bool,
  feed: PropTypes.bool,
  showCategory: PropTypes.bool,
}

ResponsivePostGrid.defaultProps = {
  showCategory: true,
}

/**
 * Styles
 */

const StyledAd = styled(VerticalAd)`
  margin-top: ${props => (props.title ? '76' : '10')}px;
  align-items: center;
  display: flex;
`

const AdColumn = styled(Col)`
  padding: 0;
`

const GridColumn = styled(Col)`
  ${props =>
    props.contained &&
    config(props).media.md`
        padding-right: 1.75rem;
        padding-left: 1.75rem
    `}
`

const StyledRow = styled(Row)`
  justify-content: center;

  ${props => config(props).media.lg`
    justify-content: flex-start;
  `}
`

export default ResponsivePostGrid
