/* eslint-disable no-underscore-dangle */
import React from 'react'
import { config, Visible } from 'react-awesome-styled-grid'
import { get, isNil } from 'lodash'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Category from '~/components/Category'
import { getStaleDate } from '~/helpers/utils'

/**
 * Constants
 */

const XS_BLOCK_HEIGHT = 200
const MD_BLOCK_HEIGHT = 300

/**
 * Helpers
 */

const getImageComponent = (isMain, isBottomRow) => {
  if (isMain) {
    return MainImage
  }

  if (isBottomRow) {
    return BottomRowImage
  }

  return SecondaryImage
}

/**
 * FeaturedItem
 */

const FeaturedItem = ({ item, isMain, showCategory, isBottomRow, showDate }) => {
  const ImageComponent = getImageComponent(isMain, isBottomRow)
  const TitleComponent = isMain ? MainTitleText : TitleText
  const category = get(item, 'category')
  const slug = get(item, 'slug')
  const originalImage = get(item, 'images.original')
  const title = get(item, 'title')
  const excerpt = get(item, 'excerpt')
  const createdAt = get(item, 'createdAt')
  const createdAgo = getStaleDate(createdAt)
  const shouldShowCategory = showCategory && !isNil(category)
  const categorySize = isMain ? 'big' : null

  return (
    <Link href="/[slug]" as={`/${slug}`}>
      <InfoContainer>
        <ImageComponent src={originalImage}>
          <Gradient />
          {shouldShowCategory && <Category size={categorySize} category={category} />}
          <Link href="/[slug]" as={`/${slug}`} passHref>
            <StyledLink>
              {showDate && <Date>{createdAgo}</Date>}
              <TitleComponent>{title}</TitleComponent>
              <Visible xs={false} sm md lg xl>
                {isMain && <ExcerptComponent>{excerpt}</ExcerptComponent>}
              </Visible>
            </StyledLink>
          </Link>
        </ImageComponent>
      </InfoContainer>
    </Link>
  )
}

/**
 * PropTypes
 */

FeaturedItem.propTypes = {
  item: PropTypes.object.isRequired,
  isMain: PropTypes.bool,
  showCategory: PropTypes.bool,
  isBottomRow: PropTypes.bool,
  showDate: PropTypes.bool,
}

FeaturedItem.defaultProps = {
  isMain: false,
  showCategory: true,
  isBottomRow: false,
  showDate: false,
}

/**
 * Styled Components
 */

const Image = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  background-image: url(${props => props.src});
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
  padding: 10px;

  ${props => config(props).media.lg`
    padding: 30px;
  `}
`

const MainImage = styled(Image)`
  height: ${XS_BLOCK_HEIGHT}px;

  ${props => config(props).media.md`
    height: ${MD_BLOCK_HEIGHT}px;
  `}

  ${props => config(props).media.lg`
    height: 500px;
  `}
`

const SecondaryImage = styled(Image)`
  height: ${XS_BLOCK_HEIGHT}px;

  ${props => config(props).media.md`
    height: ${MD_BLOCK_HEIGHT}px;
  `}

  ${props => config(props).media.lg`
    height: 100%;
  `}
`

const BottomRowImage = styled(Image)`
  height: ${XS_BLOCK_HEIGHT}px;

  ${props => config(props).media.md`
    height: ${MD_BLOCK_HEIGHT}px;
  `}

  ${props => config(props).media.lg`
    height: 250px;
  `}
`

const InfoContainer = styled.div`
  flex: 1;

  ${props => config(props).media.lg`
    margin-bottom: 10px;
    box-shadow: 1px 1px 4px #ccc;
  `}
`

const StyledLink = styled.a`
  text-decoration: none;
  z-index: 1;
`

const Date = styled.p`
  margin: 2px 0;
  font-size: 12px;
  color: ${props => props.theme.colors.textOverImage};
  cursor: pointer;
  z-index: 1;
  text-shadow: 1px 1px 1px #000;
  opacity: 1;
`

const TitleText = styled.h1`
  margin: 0;
  font-size: 24px;
  color: ${props => props.theme.colors.textOverImage};
  cursor: pointer;
  z-index: 1;
  text-shadow: 1px 1px 1px #000;
  opacity: 1;
`

const ExcerptComponent = styled.h1`
  margin: 0;
  font-size: 18px;
  color: ${props => props.theme.colors.textOverImage};
  cursor: pointer;
  z-index: 1;
  padding: 4px 2px;
  font-weight: 400;
  text-shadow: 1px 1px 1px #000;
  opacity: 1;
`

const MainTitleText = styled(TitleText)`
  ${props => config(props).media.lg`
    font-size: 52px;
  `}
`

const Gradient = styled.div`
  background-image: linear-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.8));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

/**
 * Exports
 */

export default FeaturedItem
