import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { filter, sample, toNumber } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { fetchAdsList } from '~/api'

/**
 * HorizontalAd
 */

const HorizontalAd = ({ name }) => {
  const { data } = useQuery(name, fetchAdsList)

  const horizontalAds = useMemo(() => filter(data, item => toNumber(item.height) < toNumber(item.width)), [data])
  const sampleAd = useMemo(() => sample(horizontalAds), [horizontalAds])

  return (
    <HorizontalContainer>
      {sampleAd && (
        <a href={sampleAd.link}>
          <img alt={sampleAd.title} src={sampleAd.img} height={sampleAd.height} width={sampleAd.width} />
        </a>
      )}
    </HorizontalContainer>
  )
}

/**
 * PropTypes
 */

HorizontalAd.propTypes = {
  name: PropTypes.string,
}

/**
 * Styles
 */

const HorizontalContainer = styled.div`
  display: flex;
  margin: 20px auto 30px;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 970px;
  height: 250px;
`

/**
 * Exports
 */

export default React.memo(HorizontalAd)
