import React, { useRef } from 'react'
import { Col, Container, Row } from 'react-awesome-styled-grid'
import { useInfiniteQuery } from 'react-query'
import { isEmpty, isNil, last } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { BASE_PAGE } from '~/api'
import ResponsivePostGrid from './ResponsivePostGrid'

/**
 * Constants
 */

const DEFAULT_OFFSET = 0
const DEFAULT_OFFSET_INCREMENT = 12

/**
 * Helpers
 */

const fetchMoreButtonText = (canFetchMore, isFetchingMore) => {
  if (!canFetchMore) {
    return 'No hay más resultados'
  }

  if (isFetchingMore) {
    return 'Cargando...'
  }

  return 'Ver más'
}

/**
 * InfinitePosts
 */

const InfinitePosts = ({
  className,
  initialRecentPosts,
  title,
  fetch,
  queryKey,
  contained,
  feed,
  initialOffset,
  offsetIncrement,
  showCategory,
  total,
}) => {
  const initialOffsetRefValue = !isNil(initialOffset) ? initialOffset : DEFAULT_OFFSET
  const offsetRef = useRef(initialOffsetRefValue)

  const shouldShowMoreButton = total ? total >= BASE_PAGE : true

  const { data, isFetchingMore, fetchMore } = useInfiniteQuery(['infinite-posts', queryKey], fetch, {
    initialData: [initialRecentPosts],
    getFetchMore: () => {
      offsetRef.current += offsetIncrement || DEFAULT_OFFSET_INCREMENT

      return offsetRef.current
    },
  })

  const canFetchMore = !isEmpty(last(data))

  const handleFetchMoreClick = () => {
    fetchMore()
  }

  return (
    <Container>
      <ResponsivePostGrid
        feed={feed}
        className={className}
        data={data}
        title={title}
        contained={contained}
        showCategory={showCategory}
      />
      {shouldShowMoreButton && (
        <Row>
          <Col lg={12}>
            <FetchMoreButton
              onClick={handleFetchMoreClick}
              disabled={!canFetchMore || isFetchingMore}
              fetching={isFetchingMore}
            >
              {fetchMoreButtonText(canFetchMore, isFetchingMore)}
            </FetchMoreButton>
          </Col>
        </Row>
      )}
      {!shouldShowMoreButton && <VSpacer />}
    </Container>
  )
}

/**
 * PropTypes
 */

InfinitePosts.propTypes = {
  initialRecentPosts: PropTypes.array,
  className: PropTypes.string,
  title: PropTypes.string,
  fetch: PropTypes.func,
  queryKey: PropTypes.string,
  contained: PropTypes.bool,
  feed: PropTypes.bool,
  initialOffset: PropTypes.number,
  offsetIncrement: PropTypes.number,
  showCategory: PropTypes.bool,
  total: PropTypes.number,
}

InfinitePosts.defaultProps = {
  contained: false,
  showCategory: true,
  total: undefined,
}

/**
 * Styles
 */

const FetchMoreButton = styled.button`
  margin: 30px 0;
  padding: 12px 24px;
  background-color: ${props => (!props.fetching ? props.theme.colors.primary : props.theme.colors.primaryTinted)};
  color: ${props => props.theme.colors.textOverImage};
  font-size: 20px;
  font-weight: 700;
  box-shadow: none;
  border: none;
  width: 300px;
  align-self: center;
  border-radius: 5px;
  cursor: ${props => (!props.fetching ? 'pointer' : 'progress')};

  &:hover {
    background-color: ${props => props.theme.colors.primaryTinted};
  }
`

const VSpacer = styled.div`
  height: 50px;
  margin 10px 0;
`

/**
 * Exports
 */

export default InfinitePosts
