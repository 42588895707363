import React from 'react'
import { get, isNil } from 'lodash'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Category from '~/components/Category'
import { getStaleDate } from '~/helpers/utils'

/**
 * BlockItem Component
 */

const BlockItem = ({ item, onPress, showCategory }) => {
  const { id, images, title, slug, category, createdAt, excerpt } = item

  const image = get(images, 'medium')

  return (
    <StyledLink>
      <Container onClick={onPress}>
        <Link key={id} href="/[slug]" as={`/${slug}`} passHref>
          <a aria-label={`Ir a artículo ${title}`}>
            <Image src={image} />
          </a>
        </Link>
        <Info>
          {showCategory && !isNil(category) && <Category category={category} />}
          <Link key={id} href="/[slug]" as={`/${slug}`} passHref>
            <InfoText>
              <div>
                <TitleText>{title}</TitleText>
                <ExcerptText>{excerpt}</ExcerptText>
              </div>
              <SecondaryText>{getStaleDate(createdAt)}</SecondaryText>
            </InfoText>
          </Link>
        </Info>
      </Container>
    </StyledLink>
  )
}

/**
 * PropTypes
 */

BlockItem.propTypes = {
  item: PropTypes.object.isRequired,
  onPress: PropTypes.func,
  showCategory: PropTypes.bool,
}

BlockItem.defaultProps = {
  onPress: null,
  showCategory: true,
}

/**
 * Styled Components
 */

const StyledLink = styled.div`
  text-decoration: none;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 10px 0;
  max-width: 600px;
`

const Info = styled.div`
  margin-top: 10px;
`

const InfoText = styled.a`
  text-decoration: none;
`

const Image = styled.div`
  position: relative;
  box-sizing: border-box;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  margin-right: 12px;
  width: 100%;
  height: 180px;
`

const TitleText = styled.h1`
  display: block;
  margin: 0 0 10px 0;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.colors.text};
  cursor: pointer;
`

const SecondaryText = styled.p`
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.metaText};
  margin: 0;
`

const ExcerptText = styled(SecondaryText)`
  margin-bottom: 10px;
  font-size: 16px;
  color: ${props => props.theme.colors.secondaryText};
`

/**
 * Exports
 */

export default BlockItem
