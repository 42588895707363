import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { filter, sample, toNumber } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { fetchAdsList } from '~/api'

/**
 * VerticalAd
 */

const VerticalAd = ({ className, centered, name }) => {
  const { data } = useQuery(name, fetchAdsList)

  const verticalAds = useMemo(
    () =>
      filter(data, item => {
        const height = toNumber(item.height)
        const width = toNumber(item.width)

        return height > width || height === width
      }),
    [data]
  )

  const sampleAd = useMemo(() => sample(verticalAds), [verticalAds])

  return (
    <MultiSizeContainer className={className} centered={centered}>
      {sampleAd && (
        <a href={sampleAd.link}>
          <img alt={sampleAd.title} src={sampleAd.img} height={sampleAd.height} width={sampleAd.width} />
        </a>
      )}
    </MultiSizeContainer>
  )
}

/**
 * PropTypes
 */

VerticalAd.propTypes = {
  className: PropTypes.string,
  centered: PropTypes.bool,
  name: PropTypes.string,
}

/**
 * Styles
 */

const MultiSizeContainer = styled.div`
  position: sticky;
  top: 110px;
  width: 300px;
  height: ${props => props.height};
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => (props.centered ? 'align-self: center' : 'align-self: flex-end')};
`

/**
 * Exports
 */

export default React.memo(VerticalAd)
