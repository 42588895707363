import React from 'react'
import { Col, config, Row } from 'react-awesome-styled-grid'
import { isEmpty, slice } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import FeaturedItem from './FeaturedItem'

/**
 * Helpers
 */

const getSecondaryItems = data => {
  if (data.length === 1 || data.length === 0) {
    return []
  }

  if (data.length === 3) {
    return slice(data, 1, 3)
  }

  if (data.length < 6) {
    return [data[1]]
  }

  return slice(data, 1, 3)
}

const getBottomRowItems = data => {
  if (data.length <= 3) {
    return []
  }

  return slice(data, data.length - data.length / 2, data.length)
}

const getMainItemCols = quantity => {
  if (quantity === 1) {
    return 12
  }

  if (quantity === 2) {
    return 6
  }

  return 8
}

const getSecondaryItemCols = (quantity, totalQuantity) => {
  if (quantity === 1 && totalQuantity === 2) {
    return 6
  }

  return 4
}

const getBottomRowCols = quantity => {
  if (quantity === 2) {
    return 6
  }

  if (quantity === 1) {
    return 12
  }

  return 4
}

/**
 * FeaturedItems
 */

const FeaturedItems = ({ data: items, showCategory, showDate, showBottomRow }) => {
  const data = slice(items, 0, 6)
  const mainItem = data[0]
  const secondaryItems = getSecondaryItems(data)
  const bottomRowItems = getBottomRowItems(data)
  const mainItemCols = getMainItemCols(data.length)
  const secondaryItemCols = getSecondaryItemCols(secondaryItems.length, data.length)
  const bottomRowCols = getBottomRowCols(bottomRowItems.length)
  const shouldShowBottomRow = showBottomRow && !isEmpty(bottomRowItems) && bottomRowItems.length >= 1

  return (
    <CustomRow>
      <MainCol addPadding={data.length > 1} xs={12} sm={12} md={12} lg={mainItemCols}>
        <FeaturedItem isMain item={mainItem} showCategory={showCategory} showDate={showDate} />
      </MainCol>
      <CustomCol xs={12} sm={12} md={12} lg={secondaryItemCols}>
        {!isEmpty(secondaryItems) &&
          secondaryItems.map(item => (
            <FeaturedItem key={item.id} item={item} showCategory={showCategory} showDate={showDate} />
          ))}
      </CustomCol>
      {shouldShowBottomRow &&
        bottomRowItems.map(item => {
          return (
            <BottomItemCol key={item.id} xs={12} sm={12} md={12} lg={bottomRowCols}>
              <FeaturedItem isBottomRow key={item.id} item={item} showCategory={showCategory} showDate={showDate} />
            </BottomItemCol>
          )
        })}
    </CustomRow>
  )
}

/**
 * PropTypes
 */

FeaturedItems.propTypes = {
  data: PropTypes.array,
  showCategory: PropTypes.bool,
  showDate: PropTypes.bool,
  showBottomRow: PropTypes.bool,
}

FeaturedItems.defaultProps = {
  showCategory: true,
  showDate: false,
  showBottomRow: true,
}

/**
 * Styled Components
 */

const CustomRow = styled(Row)`
  width: 100%;
  margin: 0 !important;
`

const CustomCol = styled(Col)`
  padding: 0 !important;
`

const BottomItemCol = styled(Col)`
  padding: 0 !important;
  ${props => config(props).media.lg`
    padding-right: 10px !important;
    
    &:last-child {
      padding-right: 0 !important;
    }
  `}
`

const MainCol = styled(CustomCol)`
  ${props =>
    props.addPadding &&
    config(props).media.lg`
    padding-right: 10px !important;
  `}
`

/**
 * Exports
 */

export default FeaturedItems
